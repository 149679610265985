<template>
  <v-row class="ma-2" v-if="user && user.email === 'ceo@rabbiitfirm.com'">
    <v-col cols="12">
      <v-container fluid grid-list-md px-0 py-0>
        <v-row>
          <v-col cols="12" lg="3" md="4" sm="12" xs="12" v-for="(item, key) in dashboardItems" :key="key">
            <dashboard-widget :widget-items="item"/>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
  <v-row class="ma-2" justify="center" v-else>
    <v-col cols="12">
      <v-card
          class="mx-auto text--white"
          color="#fafafa"
          max-width="700"
      >
        <v-card-title>
          <p class="text-h4 text--primary">
            Welcome to Rabbi IT Firm Admin v{{appVersion}}
          </p>
        </v-card-title>

        <v-card-text class="text-h5 font-weight-bold">
          "Rabbiitfirm: Advances in these fields: you'll be capable to rapidly publish informational and promotional articles/contents on most surveyed websites. Use professional guestposing and outreach services to acquire high-authority backlinks from trustworthy platforms and classify the site to be gathered."
        </v-card-text>

        <v-card-actions>
          <v-list-item class="grow">
            <v-row
                align="center"
                justify="end"
            >
              <social-icon v-for="(item, key) in socialData" :link="item.link" :icon="item.icon" :key="key"/>
            </v-row>
          </v-list-item>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SocialIcon from "../components/buttons/SocialIcon";
import DashboardWidget from "../components/widget/DashboardWidget";
import moment from "moment/moment";
import {mapState} from "vuex";
import utility from "../helper/utility";
export default {
  name: "Home",
  components: {DashboardWidget, SocialIcon},
  data() {
    return {
      socialData: [
        {
          icon: 'mdi-facebook',
          link: 'https://www.facebook.com/rabbiitfirm/'
        },
        {
          icon: 'mdi-youtube',
          link: '#'
        },
        {
          icon: 'mdi-linkedin',
          link: '#'
        },
        {
          icon: 'mdi-twitter',
          link: '#'
        }
      ],
      dashboardItems: [
        {
          title: 'Customers',
          type: 'reseller',
          value: 0,
          icon: 'mdi-account-group',
          color: '#5253a2',
          action: '/invoices'
        },
        {
          title: 'Orders',
          type: 'orders',
          value: 0,
          icon: 'mdi-book-open-variant',
          color: '#5253a2',
          action: '/sale-counters'
        },
        {
          title: 'Invoices',
          type: 'invoices',
          value: 0,
          icon: 'mdi-book-open-variant',
          color: '#5253a2',
          action: '/invoices'
        },
      ],
      appVersion: localStorage.getItem('app-version'),
      selectedDate: {
        startDate: moment().startOf('days').format('YYYY-MM-DD'),
        endDate: moment().endOf('days').format('YYYY-MM-DD'),
        groupBy: 'd'
      },
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
  },
  mounted() {
    this.dSummaryHandler();
  },
  methods: {
    dSummaryHandler() {
      this.$store.dispatch('loadDSummaries')
          .then(({data}) => {
            const {
              totalReseller,
              totalInvoice,
              totalOrders
            } = data;
            this.dashboardItems = this.dashboardItems.map((item) => {
              switch (item.type) {
                case 'invoices':
                  item.value = totalInvoice;
                  return item;
                case 'reseller':
                  item.value = totalReseller;
                  return item;
                case 'orders':
                  item.value = totalOrders;
                  return item;
                default:
                  return item
              }
            })
          })
          .catch((error) => {
            console.log(error);
          })
    },
  }
}
</script>

